import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FlexView from "react-flexview";
import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import "./Screen.css";

const UndoBar = ({
  handleExit,
  handleUndoAll,
  handleUndo,
  handleRedo,
  handleRedoAll,
  board,
  enableNavigation = true
}) => {
  return (
    <React.Fragment>
      <div className="undobar-div">
        <FlexView hAlignContent="center" vAlignContent="top">
          <button
            onClick={handleExit}
            className={
              board.isWon() || board.isFull()
                ? "btn-success btn exit-btn"
                : "btn-secondary btn exit-btn"
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            onClick={enableNavigation ? handleUndoAll : null}
            className={
              enableNavigation
                ? "btn-primary btn undo-btn"
                : "btn-secondary btn undo-btn"
            }
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </button>
          <button
            onClick={enableNavigation ? handleUndo : null}
            className={
              enableNavigation && board.hasUndoMove()
                ? "btn btn-primary btn undo-btn"
                : "btn btn-secondary btn undo-btn"
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            onClick={enableNavigation ? handleRedo : null}
            className={
              enableNavigation && board.hasRedoMove()
                ? "btn btn-primary btn undo-btn"
                : "btn btn-secondary btn undo-btn"
            }
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
          <button
            onClick={enableNavigation ? handleRedoAll : null}
            className={
              enableNavigation && board.hasRedoMove()
                ? "btn btn-primary btn undo-btn"
                : "btn btn-secondary btn undo-btn"
            }
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </button>
        </FlexView>
      </div>
    </React.Fragment>
  );
};

export default UndoBar;
