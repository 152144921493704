import GomokuBoard from "./GomokuBoard";
import { Stack } from "buckets-js";

class UndoGomokuBoard extends GomokuBoard {
  constructor(signMap = [], player = 1) {
    super(signMap, player);

    this.moveStack = Stack();
    this.forwardStack = Stack();
  }
  clone() {
    let result = new UndoGomokuBoard(
      this.signMap.map(row => [...row]),
      this.player
    );
    this.moveStack
      .toArray()
      .reverse()
      .forEach(umove => result.moveStack.push(umove));
    this.forwardStack
      .toArray()
      .reverse()
      .forEach(fmove => result.forwardStack.push(fmove));
    return result;
  }
  clear() {
    super.clear();
    this.moveStack.clear();
    this.forwardStack.clear();
  }

  makeMove(sign, vertex, { preventOverwrite = false } = {}) {
    let board = super.makeMove(sign, vertex, preventOverwrite);
    board.moveStack.push({ vertex, sign });
    board.forwardStack.clear();

    return board;
  }

  markerMap() {
    let mmap = [...Array(this.height)].map(_ => Array(this.width).fill(null));
    let point = { type: "point" };

    if (this.hasUndoMove()) {
      let move = this.moveStack.peek();
      let { vertex: lastmove_vertex } = move;
      let [x, y] = lastmove_vertex;
      mmap[y][x] = point;
    }
    return mmap;
  }

  undoAll() {
    var board = this;
    while (board.hasUndoMove()) {
      board = board.undoMove();
    }
    return board;
  }
  redoAll() {
    var board = this;
    while (board.hasRedoMove()) {
      board = board.redoMove();
    }
    return board;
  }

  // Is an undo move available?
  hasUndoMove() {
    return !this.moveStack.isEmpty();
  }
  hasTwoUndoMoves() {
    return this.moveStack.size() >= 2;
  }
  undoMove() {
    // End of move stack
    if (!this.hasUndoMove()) {
      return this;
    }

    let board = this.clone();
    let move = board.moveStack.pop();
    board.forwardStack.push(move);
    const { vertex: undo_vertex } = move;
    board.set(undo_vertex, 0);
    board.player *= -1;
    return board;
  }
  undoTwoMoves() {
    let board1 = this.undoMove();
    return board1.undoMove();
  }

  // Is a redo move available?
  hasRedoMove() {
    return !this.forwardStack.isEmpty();
  }
  hasTwoRedoMoves() {
    return this.forwardStack.size() >= 2;
  }
  redoMove() {
    if (!this.hasRedoMove()) {
      return this;
    }

    let move = this.forwardStack.pop();
    const { vertex, sign } = move;
    let board = super.makeMove(sign, vertex);
    board.moveStack.push(move);
    board.player = -sign;
    return board;
  }
  redoTwoMoves() {
    let board1 = this.redoMove();
    return board1.redoMove();
  }
}

UndoGomokuBoard.fromDimensions = (width, height = null) => {
  if (height == null) height = width;
  let signMap = [...Array(height)].map(_ => Array(width).fill(0));
  return new UndoGomokuBoard(signMap);
};

export default UndoGomokuBoard;
