import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AddToHomescreen from "react-add-to-homescreen";

import Sound from "react-sound";
//import logo from "./logo.svg";
import bgmusic from "./assets/LandrasDream.mp3";
import GomokuLocal from "./components/GomokuLocal";
import GomokuAI from "./components/GomokuAI";
import SplashScreen from "./components/SplashScreen";
import AISetupScreen from "./components/AISetupScreen";
import SetupScreen from "./components/SetupScreen";
import Credits from "./components/Credits";
import GameRules from "./components/GameRules";
import NotFound from "./components/NotFound";

import maxigoicon from "./assets/logo192.png";

import "./App.css";

class App extends Component {
  state = {};

  constructor(props) {
    window.soundManager.setup({
      debugMode: false,
      ignoreMobileRestrictions: true
    });
    super(props);
  }

  handleAddToHomescreenClick = () => {
    alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
  };
  render() {
    return (
      <div className="App">
        <AddToHomescreen
          onAddToHomescreenClick={this.handleAddToHomescreenClick}
          title="Install MaxiGo as App"
          icon={maxigoicon}
        />
        <Sound
          url={bgmusic}
          playStatus={Sound.status.PLAYING}
          volume={30}
          autoLoad={true}
          loop={true}
        />
        <div className="screen">
          <Switch>
            <Route path="/rules" component={GameRules} />
            <Route path="/about" component={Credits} />
            <Route path="/setup" component={SetupScreen} />
            <Route
              path="/gomoku/local"
              exact
              render={props => <GomokuLocal boardSize={15} {...props} />}
            />
            <Route
              path="/gomoku/ai/setup"
              exact
              render={props => <AISetupScreen {...props} />}
            />
            <Route
              path="/gomoku/ai/play/:playercolor/:difficulty"
              exact
              render={props => <GomokuAI boardSize={15} {...props} />}
            />
            <Route path="/not-found" component={NotFound} />
            <Route path="/" exact component={SplashScreen} />
            <Redirect to="/not-found" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
