import React from "react";
import FlexView from "react-flexview";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./Screen.css";

const ExitButton = () => {
  return (
    <FlexView column hAlignContent="center" vAlignContent="top">
      <Link to="/">
        <button className="btn btn-success gametype-btn">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Link>
    </FlexView>
  );
};

export default ExitButton;
