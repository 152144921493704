import React, { Component } from "react";
import FlexView from "react-flexview";
import withSizes from "react-sizes";

import UndoGomokuBoard from "../UndoGomokuBoard";
import ActivePlayerBar from "./ActivePlayerBar";
import UndoBar from "./UndoBar";

import Sound from "react-sound";
import getRandomClickSound from "../ClickerSound";

//import WebWorker from "../WebWorker";
import GomokuAIPlayerWorker from "../GomokuAIPlayer.worker";

const { Goban } = require("@sabaki/shudan");
// https://github.com/SabakiHQ/go-board
//const Board = require('@sabaki/go-board');

class GomokuAI extends Component {
  constructor(props) {
    super(props);

    let boardSize = props.boardSize;
    this.state = {
      player_turn: this.props.match.params.playercolor === "black",
      board: UndoGomokuBoard.fromDimensions(boardSize, boardSize),
      click_sound_url: "",
      click_sound_status: Sound.status.STOPPED
    };
    this.worker = new GomokuAIPlayerWorker();
  }

  makeAIMove(board) {
    let difficulty = this.props.match.params.difficulty / 100.0;

    this.worker.postMessage({
      signMap: board.signMap,
      player: board.player,
      difficulty: difficulty
    });
  }

  componentDidMount() {
    //this.worker = new GomokuAIPlayerWorker();
    this.worker.addEventListener("message", event => {
      const { y, x } = event.data;
      console.log("ai: ", event.data);
      this.playClickSound();

      let new_board = this.state.board.makePlayerMove([x, y]);
      this.setState({ board: new_board, player_turn: true });
    });

    // If AI has the first turn, trigger computation
    if (!this.state.player_turn) {
      this.makeAIMove(this.state.board);
    }
  }

  // Play random "click" sound once stone is put down
  playClickSound = () => {
    let click_sound_url = getRandomClickSound();
    let click_sound_status = Sound.status.PLAYING;
    this.setState({ click_sound_url, click_sound_status });
  };
  handleExit = () => {
    this.props.history.replace("/setup");
  };
  handleUndoAll = () => {
    let undo_board = this.state.board.undoAll();
    if (this.props.match.params.playercolor === "white") {
      // User plays white, hence redo first move by black
      let undo_but_one_board = undo_board.redoMove();
      this.setState({ board: undo_but_one_board, player_turn: true });
    } else {
      // User plays black
      this.setState({ board: undo_board, player_turn: true });
    }
  };
  handleRedoAll = () => {
    let redo_board = this.state.board.redoAll();
    this.setState({ board: redo_board });
  };
  handleUndo = () => {
    if (!this.state.player_turn && this.state.board.isWon()) {
      // Undo by player after a win
      let undo_board = this.state.board.undoMove();
      this.setState({ board: undo_board, player_turn: true });
    } else {
      // Regular undo
      let undo_board = this.state.board.undoTwoMoves();
      if (
        undo_board.isEmpty() &&
        this.props.match.params.playercolor === "white"
      ) {
        // User plays white, hence redo first move by black
        let undo_but_one_board = undo_board.redoMove();
        this.setState({ board: undo_but_one_board, player_turn: true });
      } else {
        this.setState({ board: undo_board });
      }
    }
  };
  handleRedo = () => {
    let redo_board = this.state.board.redoTwoMoves();
    this.setState({ board: redo_board });
  };
  handleBoardVertexMouseUp = (evt, [x, y]) => {
    if (
      this.state.player_turn &&
      this.state.board.isWon() === false &&
      this.state.board.get([x, y]) === 0
    ) {
      let new_board = this.state.board.makePlayerMove([x, y]);
      this.playClickSound();
      this.setState({ board: new_board, player_turn: false });

      if (new_board.isWon() === false) {
        this.makeAIMove(new_board);
      }
    }
  };

  render() {
    return (
      <FlexView column hAlignContent="center" vAlignContent="top">
        <Sound
          url={this.state.click_sound_url}
          playStatus={this.state.click_sound_status}
        />
        <Goban
          vertexSize={this.props.vertexSize}
          signMap={this.state.board.signMap}
          markerMap={this.state.board.markerMap()}
          busy={!this.state.player_turn && this.state.board.isWon() === false}
          showCoordinates={false}
          fuzzyStonePlacement={false}
          selectedVertices={this.state.board.winlist}
          onVertexMouseUp={this.handleBoardVertexMouseUp}
        />
        <ActivePlayerBar
          activePlayer={this.state.board.isWon() ? 0 : this.state.board.player}
          winner={this.state.board.winner}
        />
        <UndoBar
          handleExit={this.handleExit}
          handleUndoAll={this.handleUndoAll}
          handleUndo={this.handleUndo}
          handleRedo={this.handleRedo}
          handleRedoAll={this.handleRedoAll}
          board={this.state.board}
          enableNavigation={this.state.player_turn || this.state.board.isWon()}
        />
      </FlexView>
    );
  }
}

const mapSizesToProps = ({ width, height }, { boardSize }) => ({
  vertexSize: Math.floor(
    Math.max(360, Math.min(width, height - 40)) / (boardSize + 1)
  )
});

export default withSizes(mapSizesToProps)(GomokuAI);
