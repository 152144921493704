import React from "react";
import FlexView from "react-flexview";
import Img from "react-image";
import stone_black from "../assets/stone_1.svg";
import stone_white from "../assets/stone_-1.svg";
import "./Screen.css";

const ActivePlayerBar = ({
  activePlayer = 1,
  winner = 0,
  blackPlayerName = "Black",
  whitePlayerName = "White"
}) => {
  var blackClassName = "player-btn player-1-btn btn";
  var whiteClassName = "player-btn player-2-btn btn";
  if (activePlayer === 1) {
    blackClassName += " active-player-btn btn-secondary";
    whiteClassName += " waiting-player-btn btn-secondary";
  } else if (activePlayer === -1) {
    whiteClassName += " active-player-btn btn-secondary";
    blackClassName += " waiting-player-btn btn-secondary";
  } else if (activePlayer === 0) {
    // Game has ended
    if (winner === 1) {
      // Black has won
      blackClassName += " winning-player-btn btn-success";
      whiteClassName += " losing-player-btn btn-secondary";
    } else if (winner === -1) {
      // White has won
      blackClassName += " losing-player-btn btn-secondary";
      whiteClassName += " winning-player-btn btn-success";
    } else if (winner === 0) {
      // Draw
      blackClassName += " winning-player-btn btn-success";
      whiteClassName += " winning-player-btn btn-success";
    }
  }
  return (
    <div className="player-div">
      <FlexView hAlignContent="center" vAlignContent="top">
        <button className={blackClassName}>
          <Img src={stone_black} width="24px" align="left" />
          {blackPlayerName}
        </button>
        <button className={whiteClassName}>
          <Img src={stone_white} width="24px" align="left" />
          {whitePlayerName}
        </button>
      </FlexView>
    </div>
  );
};

export default ActivePlayerBar;
