import React from "react";
import FlexView from "react-flexview";
import ExitButton from "./ExitButton";
import "./Screen.css";
const { Goban } = require("@sabaki/shudan");

const signMapExampleWins = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
];
const signMapThreats = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, -1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, -1, 0, 1, 1, 1, 0, 0, 0],
  [0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
];
const markerMapThreats = (() => {
  let _ = null;
  let L = label => ({ type: "label", label });
  let A = L("a");
  let B = L("b");
  let C = L("c");
  let D = L("d");
  let E = L("e");

  return [
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, B, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, C, _, _, _, C, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, A, _, E, _, _, _, _, D, _, _, _, D, D, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, E, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, A, _, _, _, _, _, _, E, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _]
  ];
})();

const signMapRaisingOwnThreat = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, -1, -1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 1, 1, -1, 1, -1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 0, -1, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, -1, -1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
];
const markerMapRaisingOwnThreat = (() => {
  let _ = null;
  let L = label => ({ type: "label", label });
  let A = L("a");
  let B = L("b");

  return [
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, B, B, _, _, _, B, B, _, _],
    [_, _, _, _, _, _, _, _, _, _, A, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _]
  ];
})();

const signMapDefendingThreat = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, -1, -1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 1, 1, -1, 1, -1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 0, -1, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, -1, -1, 1, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
];
const markerMapDefendingThreat = (() => {
  let _ = null;
  let L = label => ({ type: "label", label });
  let A = L("a");
  let B = L("b");

  return [
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, B, _, _],
    [_, _, _, _, _, _, _, _, _, _, A, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, B, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _]
  ];
})();

const signMapSequenceStart = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, -1, 1, 1, 1, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, -1, 1, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 1, -1, -1, -1, -1, 1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
];
const signMapSequenceEnd = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, -1, 1, 0, -1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, -1, 1, 1, 1, 1, -1, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, -1, 1, 1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 1, -1, -1, -1, -1, 1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
];
const markerMapSequenceStart = (() => {
  let _ = null;
  let L = label => ({ type: "label", label });
  let A = L("a");
  let B = L("b");
  let C = L("c");
  let D = L("d");
  let E = L("e");

  return [
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, E, _, D, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, A, B, _, _],
    [_, _, _, _, _, _, _, _, _, _, C, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, D, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _]
  ];
})();

const ExampleGoban = props => {
  return (
    <FlexView
      className="exampleGoban"
      column
      hAlignContent="center"
      vAlignContent="top"
    >
      <Goban vertexSize={18} showCoordinates={true} {...props} />
    </FlexView>
  );
};

const GameRules = () => {
  return (
    <React.Fragment>
      <FlexView column hAlignContent="left" vAlignContent="top">
        <div className="rules">
          <h4>Basic Rules</h4>
          <p>
            The game played in MaxiGo is commonly known under the names Gomoku,
            Wuziqi, Five-in-a-row, or Spoil Five. The basic rules are simple:
            there is a game board with a 15-by-15 grid of positions. There are
            two players, black and white, who each take turns at placing one
            stone of their color on an empty position of the board. Black starts
            the game. The game continues until the board is full or until one of
            the players achieves a straight line (horizontal, vertical or
            diagonal) of five or more uninterrupted stones of their own color.
          </p>
          <p>
            The following game board shows example wins for black: horizontal,
            vertical, and diagonal lines of five or more stones are wins.
          </p>
          <ExampleGoban signMap={signMapExampleWins} />
          <h4>Strategy</h4>
          <p>
            Strategy in Gomoku rests on understanding different types of
            <i>threats</i>. A threat is a play that if not defended against will
            ensure a win of the player making the threat. The following game
            board illustrates all different threat types in Gomoku.
          </p>
          <ExampleGoban signMap={signMapThreats} markerMap={markerMapThreats} />
          The different threat types are as follows:
          <ul>
            <li>
              <b>Straight Four:</b> shown at (a). If the opponent cannot win
              themselves at the next turn this yields a safe win for the player
              with the straight four threat.
            </li>
            <li>
              <b>Four:</b> shown at (b). The opponent has to defend at (b) to
              prevent a win by the threatening player.
            </li>
            <li>
              <b>Three:</b> shown at (c) and (d). In the case of (c) the
              opponent has to defend on one of the positions marked with (c) in
              order to prevent the emergence of a <b>Straight Four</b> threat at
              the next turn. In the case of (d) the opponent can defend at any
              of the positions marked with (d).
            </li>
            <li>
              <b>Broken Three:</b> shown at (e). The opponent has to play at any
              of the (e) positions to prevent a <b>Straight Four</b> threat.
              Only a play at the center (e) position prevents the possibility of
              a <b>Four</b> threat in the next move.
            </li>
          </ul>
          <p>
            The <b>order</b> of a threat is either three (<b>Three</b> and{" "}
            <b>Broken Three</b>) or four (<b>Four</b> and <b>Straight Four</b>).
            At any point in the game it is important to be aware of all open
            threats on the board by both players.
          </p>
          <p>
            If the opponent has a threat open on the board, there are only two
            possible options that prevent a loss of the game:
          </p>
          <ul>
            <li>
              <b>Raising own threat:</b> if you currently have an open threat of
              the same or higher order on the board then you can raise the order
              of the threat. In the example board below the white player has
              just played (a). But even before move (a) the black player had a{" "}
              <b>Three</b> threat (order 3) on the board at positions H9,J9,K9.
              Therefore, the black player can respond to any place (b) to raise
              the threat to order 4. A play at G9 or L9 will result in a win for
              black.
            </li>
          </ul>
          <ExampleGoban
            signMap={signMapRaisingOwnThreat}
            markerMap={markerMapRaisingOwnThreat}
          />
          <ul>
            <li>
              <b>Defending against the opponent threat:</b> the white player has
              just played (a) and the black player does not have any open
              threats on the board. Therefore the black player has to defend
              against the threat by playing one of the moves marked by (b).
            </li>
          </ul>
          <ExampleGoban
            signMap={signMapDefendingThreat}
            markerMap={markerMapDefendingThreat}
          />
          <h4>Advanced Strategy</h4>
          <p>
            To play Gomoku strongly requires a good understanding of how threats
            can be combined into <b>threat sequences</b>. The following board
            from a real game, with black to move, shows a situation that allows
            for combining a sequence of three threats to secure a win for black.
          </p>
          <ExampleGoban
            signMap={signMapSequenceStart}
            markerMap={markerMapSequenceStart}
          />
          <p>
            White has no threats on the board. Black can play at (a) to force a
            white response at (b). Then black plays (c) to make a <b>Three</b>;
            white has to respond at one of two possible positions marked (d).
            Now black plays (e) to open two threats at the same time: a{" "}
            <b>Three</b> at J10-K9-L8 and a <b>Broken Three</b> at J10-H9-F7.
            This produces the following board.
          </p>
          <ExampleGoban signMap={signMapSequenceEnd} />
          <p>
            Because white does not have any open threats the presence of two or
            more threats by black loses the game for white in the next two
            moves.
          </p>
          <p>
            Gomoku is a solved game: with perfect play the black player who
            starts the game can always secure a win. This was proven in 1994 by{" "}
            <a href="https://www.renju.se/rif/proof/Go-Moku.pdf">
              Allis, van den Herik, and Huntjens
            </a>
            . Therefore, the starting player (black) has an overwhelming
            advantage in the game.
          </p>
          <p>
            To learn more about Gomoku and to become a better Gomoku player
            yourself I recommend the following resource:
          </p>
          <ul>
            <li>
              <a href="http://gomokuworld.com/gomoku/">Gomoku World</a>
            </li>
          </ul>
          <ExitButton />
        </div>
      </FlexView>
    </React.Fragment>
  );
};

export default GameRules;
