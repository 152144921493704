import React from "react";
import FlexView from "react-flexview";
import ExitButton from "./ExitButton";
import Img from "react-image";
import birthday_image from "../assets/max-5th-birthday-cake.jpg";

import "./Screen.css";

const Credits = () => {
  return (
    <FlexView column hAlignContent="center" vAlignContent="top">
      <div className="credits-birthday">
        <h5>Happy 5th Birthday Max!</h5>
        <Img width="75%" src={birthday_image} alt="Max 5th Birthday" />
      </div>
      <div className="credits">
        <h5>Credits</h5>
        <div>
          <b>Go Board:</b> <a href="https://github.com/SabakiHQ">Sabaki team</a>
        </div>
        <div>
          <b>Artwork:</b>{" "}
          <a href="https://www.artstation.com/brawlerlopes">
            Brauller N. Lopes
          </a>{" "}
          (cartooning) and{" "}
          <a href="https://www.fiverr.com/anomi_pro/">Anomi_pro</a> (icons)
        </div>
        <div>
          <b>Music:</b>{" "}
          <a href="https://audionautix.com/">
            Creative Commons Music by Jason Shaw on Audionautix.com
          </a>
        </div>
        <div>
          <b>Programming:</b> Sebastian Nowozin
        </div>
        <ExitButton />
      </div>
    </FlexView>
  );
};

export default Credits;
