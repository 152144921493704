// Modified from https://github.com/SabakiHQ/go-board/blob/master/src/GoBoard.js
class GomokuBoard {
  constructor(signMap = [], player = 1) {
    this.signMap = signMap;
    this.player = player;
    if (player !== 1 && player !== -1) {
      throw new Error("player must be 1 or -1");
    }
    this.height = signMap.length;
    this.width = this.height === 0 ? 0 : signMap[0].length;
    this.winner = 0;
    this.winlist = [];

    if (signMap.some(row => row.length !== this.width)) {
      throw new Error("signMap is not well-formed");
    }
  }

  count_side([x, y], [xo, yo]) {
    var piece_count = 0;
    let player = this.get([x, y]);
    while (true) {
      x += xo;
      y += yo;
      if (!this.has([x, y]) || this.get([x, y]) !== player) {
        return piece_count;
      }
      piece_count += 1;
    }
  }
  count([x, y], [xo, yo]) {
    return (
      1 +
      this.count_side([x, y], [xo, yo]) +
      this.count_side([x, y], [-xo, -yo])
    );
  }
  vertices_side([x, y], [xo, yo], n) {
    var vlist = [];
    for (var i = 1; i <= n; ++i) {
      vlist = vlist.concat([[x + i * xo, y + i * yo]]);
    }
    return vlist;
  }
  winvertices_dir([x, y], [xo, yo]) {
    let left = this.count_side([x, y], [xo, yo]);
    let right = this.count_side([x, y], [-xo, -yo]);
    let rowcount = 1 + left + right;
    if (rowcount < 5) {
      return [];
    }
    var vlist = [[x, y]];
    vlist = vlist.concat(this.vertices_side([x, y], [xo, yo], left));
    vlist = vlist.concat(this.vertices_side([x, y], [-xo, -yo], right));
    return vlist;
  }
  winvertices([x, y]) {
    if (!this.has([x, y]) || this.get([x, y]) === 0) {
      return [];
    }
    let vlist_horizontal = this.winvertices_dir([x, y], [1, 0]);
    let vlist_vertical = this.winvertices_dir([x, y], [0, 1]);
    let vlist_diagonal1 = this.winvertices_dir([x, y], [1, 1]);
    let vlist_diagonal2 = this.winvertices_dir([x, y], [1, -1]);
    let vlist = vlist_horizontal.concat(
      vlist_vertical,
      vlist_diagonal1,
      vlist_diagonal2
    );
    return vlist;
  }

  get([x, y]) {
    return this.signMap[y] != null ? this.signMap[y][x] : null;
  }
  set([x, y], sign) {
    if (this.has([x, y])) {
      this.signMap[y][x] = sign;
    }

    // Check if new move causes a five-in-a-row
    this.winlist = this.winvertices([x, y]);
    if (this.winlist.length > 0) {
      this.winner = sign;
    }

    return this;
  }
  has([x, y]) {
    return 0 <= x && x <= this.width && 0 <= y && y < this.height;
  }
  isWon() {
    return this.winner !== 0;
  }

  clear() {
    this.signMap = this.signMap.map(row => row.map(_ => 0));
    this.winner = 0;
    return this;
  }

  makePlayerMove(vertex, { preventOverwrite = false } = {}) {
    let board = this.makeMove(this.player, vertex, {
      preventOverwrite: preventOverwrite
    });
    board.player *= -1;
    return board;
  }
  makeMove(sign, vertex, { preventOverwrite = false } = {}) {
    let board = this.clone();
    if (sign === 0 || !this.has(vertex)) return board;
    if (preventOverwrite && !!this.get(vertex)) {
      throw new Error("Overwrite prevented");
    }
    sign = sign > 0 ? 1 : -1;
    board.set(vertex, sign);
    return board;
  }
  isSquare() {
    return this.width === this.height;
  }
  isEmpty() {
    return this.signMap.every(row => row.every(x => !x));
  }
  isFull() {
    var emptyCount = 0;
    for (var y = 0; y < this.height; ++y) {
      for (var x = 0; x < this.width; ++x) {
        if (this.get([x, y]) === 0) {
          emptyCount += 1;
        }
      }
    }
    return emptyCount === 0;
  }
  clone() {
    let result = new GomokuBoard(
      this.signMap.map(row => [...row]),
      this.player
    );
    return result;
  }
}

GomokuBoard.fromDimensions = (width, height = null) => {
  if (height == null) height = width;
  let signMap = [...Array(height)].map(_ => Array(width).fill(0));
  return new GomokuBoard(signMap);
};

export default GomokuBoard;
