import React from "react";
import { Link } from "react-router-dom";
import FlexView from "react-flexview";
import withSizes from "react-sizes";

import Img from "react-image";
import splash from "../assets/maxigo-splash.png";
import "./Screen.css";

const SplashScreen = props => {
  return (
    <FlexView column hAlignContent="center" vAlignContent="top">
      <Img width={props.widthpixels + "px"} src={splash} alt="MaxiGo logo" />
      <FlexView hAlignContent="center" vAlignContent="top">
        <Link to="/rules">
          <button className="btn btn-secondary splash-start-btn">Rules</button>
        </Link>
        <Link to="/setup">
          <button className="btn btn-success splash-start-btn">Start</button>
        </Link>
        <Link to="/about">
          <button className="btn btn-secondary splash-start-btn">About</button>
        </Link>
      </FlexView>
    </FlexView>
  );
};

const mapSizesToProps = ({ width, height }) => {
  // Sizes of maxigo-splash.png
  let wratio = width / 1020;
  let hratio = height / 1468;
  var widthpixels = 0;
  if (wratio <= hratio) {
    // Width is limiting size
    widthpixels = 0.85 * wratio * 1020;
  } else {
    // Height is limiting size
    widthpixels = 0.85 * 1020 * hratio;
  }
  return { widthpixels };
};

export default withSizes(mapSizesToProps)(SplashScreen);
