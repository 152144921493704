import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Link to="/">
      <button className="btn-primary">Restart</button>
    </Link>
  );
};

export default NotFound;
