import React, { Component } from "react";
import FlexView from "react-flexview";
import withSizes from "react-sizes";

import UndoGomokuBoard from "../UndoGomokuBoard";
import UndoBar from "./UndoBar";
import ActivePlayerBar from "./ActivePlayerBar";

import Sound from "react-sound";
import getRandomClickSound from "../ClickerSound";
import "./Screen.css";

const { Goban } = require("@sabaki/shudan");

// https://github.com/SabakiHQ/go-board
//const Board = require('@sabaki/go-board');

class GomokuLocal extends Component {
  constructor(props) {
    super(props);

    let boardSize = props.boardSize;
    this.state = {
      //      board: new UndoGomokuBoard(signMap, 1)
      board: UndoGomokuBoard.fromDimensions(boardSize, boardSize),
      click_sound_url: "",
      click_sound_status: Sound.status.STOPPED
    };
  }

  // Play random "click" sound once stone is put down
  playClickSound = () => {
    let click_sound_url = getRandomClickSound();
    let click_sound_status = Sound.status.PLAYING;
    this.setState({ click_sound_url, click_sound_status });
  };
  handleExit = () => {
    this.props.history.replace("/setup");
  };
  handleUndoAll = () => {
    let undo_board = this.state.board.undoAll();
    this.setState({ board: undo_board });
  };
  handleRedoAll = () => {
    let redo_board = this.state.board.redoAll();
    this.setState({ board: redo_board });
  };
  handleUndo = () => {
    console.log("handleUndo");
    let undo_board = this.state.board.undoMove();
    this.setState({ board: undo_board });
  };
  handleRedo = () => {
    console.log("handleRedo");
    let redo_board = this.state.board.redoMove();
    this.setState({ board: redo_board });
  };

  render() {
    return (
      <FlexView column hAlignContent="center" vAlignContent="top">
        <Sound
          url={this.state.click_sound_url}
          playStatus={this.state.click_sound_status}
        />
        <Goban
          vertexSize={this.props.vertexSize}
          signMap={this.state.board.signMap}
          markerMap={this.state.board.markerMap()}
          showCoordinates={false}
          fuzzyStonePlacement={false}
          selectedVertices={this.state.board.winlist}
          onVertexMouseUp={(evt, [x, y]) => {
            if (
              this.state.board.isWon() === false &&
              this.state.board.get([x, y]) === 0
            ) {
              let new_board = this.state.board.makePlayerMove([x, y]);
              this.playClickSound();
              this.setState({ board: new_board });
            }
          }}
        />
        <ActivePlayerBar
          activePlayer={this.state.board.isWon() ? 0 : this.state.board.player}
          winner={this.state.board.winner}
        />
        <FlexView hAlignContent="center" vAlignContent="top">
          <UndoBar
            handleExit={this.handleExit}
            handleUndoAll={this.handleUndoAll}
            handleUndo={this.handleUndo}
            handleRedo={this.handleRedo}
            handleRedoAll={this.handleRedoAll}
            board={this.state.board}
          />
        </FlexView>
      </FlexView>
    );
  }
}

const mapSizesToProps = ({ width, height }, { boardSize }) => ({
  vertexSize: Math.floor(
    Math.max(360, Math.min(width, height - 40)) / (boardSize + 1)
  )
});

export default withSizes(mapSizesToProps)(GomokuLocal);
