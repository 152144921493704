import React, { Component } from "react";
import FlexView from "react-flexview";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Screen.css";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

class SetupScreen extends Component {
  state = {};

  handleAI = () => {
    this.props.history.push("/gomoku/ai/setup");
  };
  handleLocal = () => {
    this.props.history.push("/gomoku/local");
  };

  render() {
    return (
      <div className="setupscreen">
        <FlexView column hAlignContent="center" vAlignContent="center">
          <button
            onClick={this.handleAI}
            className="btn btn-primary gametype-btn"
          >
            Player vs AI
          </button>
          <button
            onClick={this.handleLocal}
            className="btn btn-primary gametype-btn"
          >
            Player vs Player (local)
          </button>
          <button className="btn btn-outline-secondary gametype-btn">
            Player vs Player (remote)
          </button>
          <Link to="/">
            <button className="btn btn-success gametype-btn">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Link>
        </FlexView>
      </div>
    );
  }
}

export default SetupScreen;
