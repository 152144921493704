import click01 from "./assets/click-01.mp3";
import click02 from "./assets/click-02.mp3";
import click03 from "./assets/click-03.mp3";
import click04 from "./assets/click-04.mp3";
import click05 from "./assets/click-05.mp3";
import click06 from "./assets/click-06.mp3";
import click07 from "./assets/click-07.mp3";
import click08 from "./assets/click-08.mp3";
import click09 from "./assets/click-09.mp3";
import click10 from "./assets/click-10.mp3";
import click11 from "./assets/click-11.mp3";
import click12 from "./assets/click-12.mp3";

const click_sounds = [
  click01,
  click02,
  click03,
  click04,
  click05,
  click06,
  click07,
  click08,
  click09,
  click10,
  click11,
  click12
];

export function getRandomClickSound() {
  let click_count = click_sounds.length;
  let csi = Math.floor(Math.random() * click_count);
  let click_sound_url = click_sounds[csi];
  return click_sound_url;
}

export default getRandomClickSound;
