import React, { Component } from "react";
import FlexView from "react-flexview";
import withSizes from "react-sizes";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Img from "react-image";

import stone_black from "../assets/stone_1.svg";
import stone_white from "../assets/stone_-1.svg";
import difficulty_baby from "../assets/maxigo-difficulty-baby.png";
import difficulty_red from "../assets/maxigo-difficulty-red.png";
import difficulty_blue from "../assets/maxigo-difficulty-blue.png";

//import "bootswatch/dist/cyborg/bootstrap.min.css";
import "../index.scss";
import { Form } from "react-bootstrap";
import "./Screen.css";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

class AISetupScreen extends Component {
  constructor(props) {
    super(props);

    this.state = { playercolor: "1", difficulty: 50 };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;

    var value = null;
    if (name === "difficulty") {
      value = target.value;
    } else if (name === "playercolor") {
      value = target.value;
    }
    this.setState({ [name]: value });
  }
  handleSubmit(event) {
    /*
    alert(
      "difficulty: " +
        this.state.difficulty +
        ", playercolor: " +
        this.state.playercolor
    );
    */
    event.preventDefault();
    let playerstring = this.state.playercolor === "1" ? "black" : "white";
    this.props.history.push(
      "/gomoku/ai/play/" + playerstring + "/" + this.state.difficulty
    );
  }

  render() {
    return (
      <div className="ai-setup">
        <FlexView
          column
          className="ai-setup"
          hAlignContent="center"
          vAlignContent="top"
        >
          <Form
            className="ai-setup"
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          >
            <div className="ai-setup-difficulty-div">
              <Form.Group controlId="aiDifficulty">
                <Form.Label className="ai-setup-label" size="lg">
                  Difficulty
                </Form.Label>
                <div className="ai-setup-difficulty-container">
                  <div className="ai-setup-difficulty-img">
                    <Img
                      src={difficulty_baby}
                      width={this.props.width_difficulty_baby + "px"}
                      align="left"
                    />
                  </div>
                  <div className="ai-setup-difficulty-img">
                    <Img
                      src={difficulty_blue}
                      width={this.props.width_difficulty_blue + "px"}
                      align="center"
                    />
                  </div>
                  <div className="ai-setup-difficulty-img">
                    <Img
                      src={difficulty_red}
                      width={this.props.width_difficulty_red + "px"}
                      align="right"
                    />
                  </div>
                </div>
                <Form.Control
                  className="custom-range ai-setup-difficulty-range"
                  name="difficulty"
                  type="range"
                  min="0"
                  max="100"
                  value={this.state.difficulty}
                  step="1"
                  onChange={this.handleChange}
                />
              </Form.Group>
            </div>
            <Form.Group controlId="playerColor">
              <Form.Label className="ai-setup-label" size="lg">
                Starting Player
              </Form.Label>
              <FlexView column hAlignContent="center">
                <FlexView column hAlignContent="left">
                  <FlexView hAlignContent="left" vAlignContent="center">
                    <Form.Check
                      className="ai-setup-radio"
                      type="radio"
                      name="playercolor"
                      id="playerColorB"
                      value="1"
                      onChange={this.handleChange}
                      checked={this.state.playercolor === "1"}
                    />
                    <Form.Label className="ai-setup-radio-label" size="lg">
                      <FlexView vAlignContent="center">
                        <span className="ai-player-desc">Player starts as</span>
                        <Img
                          src={stone_black}
                          width="24px"
                          height="24xp"
                          align="left"
                        />
                        <span className="ai-player-desc">Black</span>
                      </FlexView>
                    </Form.Label>
                  </FlexView>
                  <FlexView hAlignContent="left" vAlignContent="center">
                    <Form.Check
                      className="ai-setup-radio"
                      type="radio"
                      name="playercolor"
                      id="playerColorW"
                      value="-1"
                      onChange={this.handleChange}
                      checked={this.state.playercolor === "-1"}
                    />
                    <Form.Label className="ai-setup-radio-label" size="lg">
                      <FlexView vAlignContent="center">
                        <span className="ai-player-desc">
                          Player plays second as
                        </span>
                        <Img src={stone_white} width="24px" align="left" />
                        <span className="ai-player-desc">White</span>
                      </FlexView>
                    </Form.Label>
                  </FlexView>
                </FlexView>
              </FlexView>
            </Form.Group>
            <Form.Control
              className="ai-setup-start-btn btn btn-primary"
              type="submit"
              value="Start Game"
            />
            <Link to="/setup">
              <button className="btn btn-success gametype-btn">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </Link>
          </Form>
        </FlexView>
      </div>
    );
  }
}

const mapSizesToProps = ({ width, height }) => {
  // 0.85*width = width of difficulty range slider
  // 396 = width of difficulty images
  let width_scaling = (0.85 * width) / (1.1 * 396 * 3);
  let width_base = width_scaling * 396;
  return {
    width_difficulty_baby: Math.floor(width_base * 0.7),
    width_difficulty_blue: Math.floor(width_base * 0.6),
    width_difficulty_red: Math.floor(width_base * 1.0)
  };
};

export default withSizes(mapSizesToProps)(AISetupScreen);
